import React, { useEffect, useState, memo } from 'react'
import { Form, Input, Select, DatePicker, TimePicker, TextArea } from 'antd'
import { MdOutlineKeyboardArrowDown } from 'react-icons/md'
import { FormConatiner } from './styles'
import useGetData from '../../../hooks/useGetData'
import { useSelector } from 'react-redux'
import { RiArrowDropDownFill } from 'react-icons/ri'
import debounce from 'lodash.debounce'
import { PiDotOutlineFill } from 'react-icons/pi'
import { InstanceWithAuth } from '../../../App'
import dayjs from 'dayjs'
import moment from 'moment'
const CreateMeetingForm = memo(
  ({
    form,
    selectedDate,
    holidays,
    selectedLocation,
    selectedFormLoaction,
    setSelectedFromLoaction,
    setUser = false,
    defaultUser,
    setShowSaveBtn,
    setInitialCreateMeetingValues,
    handleCreateFormItemChange,
  }) => {
    const { auth, app } = useSelector((state) => state)
    const [showCursor, setShowCursor] = useState(false)
    const [selectedTime, setSelectedTime] = useState(null)
    const [selectedPaymentOption, setSelectedPaymentOption] = useState()
    const {
      data: locationData,
      loading: locationDataLoading,
      error: locationDataError,
    } = useGetData('locations/dropdown/get')

    const {
      data: meetingRoomsData,
      refetchData: refetchMeetingRoomsData,
      loading: loadingMeetingRoomsData,
    } = useGetData(`meeting-rooms/loaction/${selectedFormLoaction}`)

    useEffect(() => {
      selectedDate &&
        form.setFieldsValue({
          startTime: dayjs(selectedDate?.start),
          bookinDate: dayjs(selectedDate?.start),
          endTime: dayjs(selectedDate?.end),
        })
    }, [selectedDate])

    useEffect(() => {
      form.setFieldsValue({
        Location: selectedFormLoaction,
      })
      refetchMeetingRoomsData()
    }, [selectedFormLoaction])

    useEffect(() => {
      if (defaultUser) {
        fetchUsers(defaultUser)
        form.setFieldsValue({
          user: defaultUser,
        })
      }
      setInitialCreateMeetingValues({})
    }, [])

    useEffect(() => {
      if (defaultUser) {
        fetchUsers(defaultUser)
        form.setFieldsValue({
          user: defaultUser,
        })
      }
    }, [defaultUser])

    const selectLocation = (value) => {
      setSelectedFromLoaction(value)
      form.resetFields(['user', 'meetingRoom', 'paymentOption'])
    }
    // useEffect(() => {
    //   form.resetFields(['user', 'meetingRoom', 'paymentOption'])
    // }, [selectedFormLoaction])

    const onDropDownVisible = () => {
      setShowCursor(!showCursor)
    }

    const { Option } = Select
    const { TextArea } = Input

    const [options, setOptions] = useState([])
    const [userloading, setUserLoading] = useState(false)

    const fetchUsers = async (searchValue) => {
      setUserLoading(true)

      const formData = new FormData()
      formData.append('query', searchValue || '')
      formData.append('location_id', selectedFormLoaction || '')

      try {
        const response = await InstanceWithAuth.post(
          `meeting-events/users`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${auth?.userObj?.api_token}`,
            },
          }
        )

        if (response.status === 200 || response.status === 201) {
          const formattedOptions = response.data?.data.map((user) => ({
            value: user.id,
            label: (
              <div
                className="select-dropdown"
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  width: '100%',
                }}
              >
                <div
                  className="MailBox-handleUserDetails"
                  style={{
                    display: 'flex',
                    // justifyContent: 'space-between',
                    // width: '100%',
                    gap: '3px',
                  }}
                >
                  <span
                    style={{
                      color: 'var(--Dark-Gray-100, #2B3746)',
                      fontFamily: 'Outfit',
                      fontSize: '14px',
                      fontStyle: 'normal',
                      fontWeight: '400',
                      lineHeight: '120%',
                      width: '110px',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    {`${user?.contact_first_name} ${user?.contact_last_name}`}
                  </span>
                  <span
                    style={{
                      color: 'var(--Gray-60, rgba(85, 99, 115, 0.60))',
                      fontFamily: 'Outfit',
                      fontSize: '14px',
                      fontStyle: 'normal',
                      fontWeight: 400,
                      lineHeight: '120%',
                      width: '90px',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    {user?.name}
                  </span>
                </div>
                <span
                  style={{
                    display: 'flex',
                    color: 'var(--Gray-60, rgba(85, 99, 115, 0.60))',
                    fontFamily: 'Outfit',
                    fontSize: '14px',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    lineHeight: '120%',
                    width: '69px',
                  }}
                >
                  {`${user?.meeting_room_credits_left} Credits`}
                </span>
              </div>
            ),
          }))
          setOptions(formattedOptions)
        }
      } catch (error) {
        console.error('Error fetching users:', error)
      } finally {
        setUserLoading(false)
      }
    }

    const handleSearch = debounce((value) => {
      if (value) {
        fetchUsers(value)
      } else {
        setOptions([])
      }
    }, 300)
    const paymentOptions = [
      { name: 'Use Credits', id: 1 },
      { name: 'Use Credits & Payment', id: 2 },
      { name: 'Continue without charge', id: 3 },
    ]

    const handleAddPaymentOption = (value) => {
      setSelectedPaymentOption(value)
    }
    const disabledDate = (current) => {
      if (!current) return false

      const isPastDate = current < moment().startOf('day')
      const dayOfWeek = current.day()

      if (isPastDate || dayOfWeek === 0 || dayOfWeek === 6) {
        return true
      }

      const isHoliday = holidays?.includes(current.format('YYYY-MM-DD'))
      return isHoliday
    }
    return (
      <FormConatiner>
        <Form
          layout="vertical"
          requiredMark={false}
          className="form"
          // onFinish={handleSubmitAddMail}
          onValuesChange={handleCreateFormItemChange}
          // ref={addMailRef}
          initialValues={{ startTime: selectedTime }}
          form={form}
        >
          <div className="form-title">Provide booking details</div>
          <Form.Item label="Title" name="title" className="input-label">
            <Input className="username-input" placeholder="Title" />
          </Form.Item>
          <Form.Item
            label="Date"
            name="bookinDate"
            className="input-label"
            rules={[{ required: true, message: 'Select a date!' }]}
          >
            <DatePicker
              placeholder="December 5, 2021"
              format="YYYY-MM-DD"
              className="username-input"
              disabledDate={disabledDate}
            />
          </Form.Item>

          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'end',
              gap: '10px',
              width: '100%',
            }}
          >
            <Form.Item
              label="From"
              name="startTime"
              className="input-label"
              rules={[{ required: true, message: 'Select Start Time!' }]}
            >
              <TimePicker
                className="username-input two-inputs-quantity"
                format="h:mm A"
                needConfirm={false}
                suffixIcon={<RiArrowDropDownFill />}
              ></TimePicker>
            </Form.Item>
            <Form.Item
              label="Till"
              name="endTime"
              className="input-label"
              rules={[{ required: true, message: 'Select End Time!' }]}
            >
              <TimePicker
                className="username-input two-inputs-quantity"
                format="h:mm A"
                suffixIcon={<RiArrowDropDownFill />}
                needConfirm={false}
              ></TimePicker>
            </Form.Item>
          </div>
          <Form.Item
            label="Location"
            name="Location"
            className="input-label"
            rules={[{ required: true, message: 'Select Location!' }]}
            initialValue={selectedFormLoaction}
          >
            <Select
              className={
                showCursor ? 'select-input' : 'select-input hide-cursor'
              }
              suffixIcon={
                <MdOutlineKeyboardArrowDown fill="rgba(85, 99, 115, 0.6)"></MdOutlineKeyboardArrowDown>
              }
              onChange={(value) => selectLocation(value)}
              placeholder="Location"
              options={locationData?.data?.map((item) => ({
                value: item?.key,
                label: `${item?.label}, ${item?.state}`,
                companyID: item?.companyID,
              }))}
              onDropdownVisibleChange={() => onDropDownVisible()}
            />
          </Form.Item>
          <Form.Item
            label="Meeting Room"
            name="meetingRoom"
            className="input-label"
            rules={[{ required: true, message: 'Select MeetinRoom!' }]}
          >
            <Select
              className={
                showCursor ? 'select-input' : 'select-input hide-cursor'
              }
              suffixIcon={
                <MdOutlineKeyboardArrowDown fill="rgba(85, 99, 115, 0.6)"></MdOutlineKeyboardArrowDown>
              }
              placeholder="Meeting Room"
              options={meetingRoomsData?.data?.map((item) => ({
                value: item?.id,
                label: item?.name,
              }))}
              onDropdownVisibleChange={() => onDropDownVisible()}
            />
          </Form.Item>
          <Form.Item
            label="User"
            name="user"
            className="input-label"
            rules={[{ required: true, message: 'Select User!' }]}
          >
            <Select
              className={
                showCursor ? 'select-input' : 'select-input hide-cursor'
              }
              suffixIcon={
                <MdOutlineKeyboardArrowDown fill="rgba(85, 99, 115, 0.6)" />
              }
              showSearch
              placeholder="Select a User"
              onSearch={handleSearch}
              onDropdownVisibleChange={onDropDownVisible}
              options={options}
              loading={userloading}
              // notFoundContent={userloading ? 'Loading...' : 'No users found'}
              filterOption={false}
            />
          </Form.Item>
          <Form.Item
            label="Payment Option"
            name="paymentOption"
            className="input-label"
            rules={[{ required: true, message: 'Select Payment Option!' }]}
          >
            <Select
              className={
                showCursor ? 'select-input' : 'select-input hide-cursor'
              }
              suffixIcon={
                <MdOutlineKeyboardArrowDown fill="rgba(85, 99, 115, 0.6)"></MdOutlineKeyboardArrowDown>
              }
              options={paymentOptions?.map((item) => ({
                value: item?.name,
                label: item?.name,
              }))}
              onChange={(value) => handleAddPaymentOption(value)}
              placeholder="Select a Payment Option"
              onDropdownVisibleChange={() => onDropDownVisible()}
            />
          </Form.Item>
          {selectedPaymentOption == 'Buy Credits & Book' && (
            <Form.Item
              label="Quantity"
              name="quantity"
              className="input-label"
              rules={[{ required: true, message: 'Select Quantity!' }]}
            >
              <Input
                className="username-input"
                type="number"
                placeholder="Quantity"
              />
            </Form.Item>
          )}
        </Form>
        <Form
          layout="vertical"
          requiredMark={false}
          className="form"
          // onFinish={handleSubmitAddMail}
          // ref={addMailRef}
          form={form}
          onValuesChange={handleCreateFormItemChange}
          // onValuesChange={handleFormItemChange}
        >
          <Form.Item
            label="Meeting Description"
            name="notes"
            className="input-label"
          >
            <TextArea
              rows={2}
              placeholder="Description here"
              className="text-area"
              maxLength={200}
            />
          </Form.Item>
        </Form>
      </FormConatiner>
    )
  }
)

export default CreateMeetingForm
