import styled from 'styled-components'

const MainConatiner = styled.div`
  margin-top: 92px;
  background-color: #fff;
  min-height: calc(100vh - 92px);
  display: flex;
  padding: 25px;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
`
const BoardContainer = styled.div`
  width: 100%;
  .kanban-board {
    .smooth-dnd-draggable-wrapper {
      section header span {
        font-weight: 500;
      }
    }
    .smooth-dnd-draggable-wrapper {
      article header span {
        font-family: Outfit;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
      }
    }
  }
  .react-trello-board {
    overflow-x: auto;
    white-space: nowrap;
    scrollbar-width: none;
    background-color: white;
    /* padding: 20px; */
    /* border-radius: 10px; */
    width: 100%;
    padding-top: 0;
    height: calc(100vh - 250px);
    padding: 0;
    .rtd-card-close {
      display: none !important; /* Hides the close button */
    }
  }
  .smooth-dnd-container {
    /* display: flex; */
    gap: 5px;
  }

  .react-trello-lane {
    border-radius: 8px;
    border: 1px solid #e3eaee;
    background: white;
  }
  .react-trello-lane:hover {
    border-radius: 8px;
    border: 1px solid #e3eaee;
    background: rgba(241, 244, 245, 0.8);
  }
  .lane-collapsed {
    width: 50px !important; /* Important to override any default */
    transition: width 0.3s ease-in-out, transform 0.3s ease-in-out;
    transform: translateX(-100%);
    overflow: hidden;
  }

  .lane-expanded {
    width: 300px !important; /* Important to override any default */
    transition: width 0.3s ease-in-out, transform 0.3s ease-in-out;
    transform: translateX(0);
    overflow: hidden;
  }
`
export { MainConatiner, BoardContainer }
