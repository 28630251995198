import { useState } from 'react'

const TruncketTextAndHover = ({
  text,
  bgColor,
  app,
  screenSize,
  marginLeft = '-67px',
}) => {
  const [isHovered, setIsHovered] = useState(false)

  const maxLength =
    app?.sidebarCollapsed && screenSize > 1500
      ? 30
      : app?.sidebarCollapsed && screenSize < 1500
      ? 17
      : 17

  // console.log(maxLength, 'maxLengthmaxLength')
  // let truncatedText = text.substring(0, maxLength)
  // if (truncatedText.length < text.length) {
  //   truncatedText += '..'
  // }

  const truncateTextWithCustomRules = (text, maxLength) => {
    let count = 0
    let truncatedText = ''

    for (let char of text) {
      count++
      truncatedText += char
      if (count >= maxLength) break
    }

    if (truncatedText.length < text.length) {
      truncatedText += '..'
    }

    return truncatedText
  }

  const truncatedText = truncateTextWithCustomRules(text, maxLength)

  const handleMouseEnter = () => {
    setIsHovered(true)
  }

  const handleMouseLeave = () => {
    setIsHovered(false)
  }

  return (
    <div
      className="truncate-container"
      style={{
        cursor: truncatedText.length < text.length ? 'pointer' : '',
      }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {isHovered && maxLength < text?.length ? (
        <div
          className="truncatedTextOne"
          style={{
            cursor: 'pointer',
            display: 'flex',
            padding: '6px 8px',
            alignItems: 'center',
            gap: '10px',
            borderRadius: '4px',
            border: '1px solid var(--Green-70, rgba(76, 203, 96, 0.7))',
            backgroundColor: bgColor == 'bgGreen' ? '#a3e0aa' : '#fff',
            color: 'var(--Dark-Gray-100, #2b3746)',
            fontFamily: 'Outfit',
            fontSize: '12px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '120%',
            height: '32px',
            justifyContent: 'center',
            textAlign: 'center',
            position: 'absolute',
            marginTop: '-16px',
            marginLeft: marginLeft,
            zIndex: 4,
            opacity: 1,
            maxWidth: '10rem',
            width: '100%',
          }}
        >
          {text}
        </div>
      ) : (
        <div className={`truncatedTextTwo ${maxLength} `}>{truncatedText}</div>
      )}
      <div style={{ display: 'none' }} className="unTrunctaedText">
        {text}
      </div>
    </div>
  )
}
export default TruncketTextAndHover
