import { Component, lazy } from 'react'
import Support from '../pages/support/Support'
import ProductSettings from '../pages/settings/productSettings/ProductSettings'
import GeneralSettings from '../pages/settings/generalSettings/GeneralSettings'
import TicketCenter from '../pages/ticketCenter/TicketCenter'
import Ticket from '../pages/ticket/Ticket'
import ChatManager from '../pages/chatManager/ChatManager'
import PhoneNumberDetails from '../pages/phoneNumberDetails/PhoneNumberDetails'
import TemporaryPhoneNumberDetails from '../pages/temporaryPhoneNumberDetails/TemporaryPhoneNumberDetail'
import TemporaryLiveAnsweringDetails from '../pages/temporaryLiveAnsweringDetails/TemporaryLiveAnsweringDetails'
import TemporaryLiveAnsweringDetailsEdit from '../pages/temporaryLiveAnsweringDetailsEdit/TemporaryLiveAnsweringDetailsEdit'
import AdminSettings from '../pages/adminSettings/AdminSettings'
const AboutCompany = lazy(() => import('../pages/companyFlow/about/About'))
const Tickets = lazy(() => import('../pages/userList/tickets/Tickets'))
const AllInvoices = lazy(() =>
  import('../pages/userList/allInvoices/AllInvoices')
)
const AddMails = lazy(() => import('../pages/userList/addMails/AddMails'))
const AddCompanies = lazy(() =>
  import('../pages/userList/addCompany/AddCompanies')
)
const CreateAccount = lazy(() =>
  import('../pages/userList/createAccount/CreateAccount')
)
const CreateLocations = lazy(() =>
  import('../pages/createLocations/CreateLocations')
)
const AllLocation = lazy(() => import('../pages/addLocation/AllLocation'))
const EditEmployees = lazy(() => import('../pages/editEmployees/EditEmployees'))
const Login = lazy(() => import('../pages/auth/Login'))
const PasswordReset = lazy(() =>
  import('../pages/auth/passwordReset/PasswordReset')
)
const ForgetPassword = lazy(() => import('../pages/auth/ForgetPassword'))
const SuccessScreen = lazy(() =>
  import('../pages/auth/successScreen/SuccessScreen')
)
const CreatePassword = lazy(() =>
  import('../pages/auth/createPassword/CreatePassword')
)
const AllEmployees = lazy(() => import('../pages/employees/AllEmployees'))
const AddEmployees = lazy(() => import('../pages/addEmployees/AddEmployees'))
const EditLocation = lazy(() => import('../pages/editLocation/EditLocation'))
const UserList = lazy(() => import('../pages/userList/UserList'))
const AllEmployeesList = lazy(() =>
  import('../pages/employees/AllEmployeesList')
)
const UserOverview = lazy(() => import('../pages/userOverview/UserOverview'))
const UserPayment = lazy(() => import('../pages/userPayments/UserPayments'))
const UserActivity = lazy(() => import('../pages/userActivity/UserActivity'))
const CompanyOverview = lazy(() =>
  import('../pages/companyFlow/companyOverview/CompnayOverview')
)
const PlansAndProducts = lazy(() =>
  import('../pages/companyFlow/planAndProducts/PlansAndProducts')
)
const CompnayMailBox = lazy(() =>
  import('../pages/companyFlow/compnayMailBox/CompnayMailBox')
)
const LiveAnswering = lazy(() =>
  import('../pages/companyFlow/liveAnswering/LiveAnswering')
)
const PhoneNumber = lazy(() =>
  import('../pages/companyFlow/phoneNumber/PhoneNumber')
)
const CompnayPayments = lazy(() =>
  import('../pages/companyFlow/compnayPayments/CompnayPayments')
)
const CompnayInvoices = lazy(() =>
  import('../pages/companyFlow/companyInvoices/CompanyInvoices')
)
const CompnayActivity = lazy(() =>
  import('../pages/companyFlow/companyActivity/CompnayActivity')
)
const CompnaySupport = lazy(() =>
  import('../pages/companyFlow/compnaySupport/CompnaySupport')
)
const AllCompanies = lazy(() =>
  import('../pages/companyFlow/allCompanies/AllCompanies')
)
const PlanAndProducts = lazy(() =>
  import('../pages/planAndProducts/PlanAndProducts')
)
const CreatePlan = lazy(() =>
  import('../pages/planAndProducts/addPlan/AddNewPlan')
)
const EditPlan = lazy(() =>
  import('../pages/planAndProducts/editPlan/EditPlan')
)
const CreateAddOns = lazy(() =>
  import('../pages/addOns/create-addons/CreateAddons')
)
const LiveAnsweringPage = lazy(() =>
  import('../pages/liveAnswering/LiveAnswring/LiveAnswring')
)

const AddNewCall = lazy(() =>
  import('../pages/liveAnswering/LiveAnswring/addCall/AddCall')
)

const AddOns = lazy(() => import('../pages/addOns/AddOns'))
const EditAddOns = lazy(() => import('../pages/addOns/edit-addons/EditAddons'))
const UserFlow = lazy(() => import('../pages/userFlow/UserFlow'))
const CompanieFlow = lazy(() => import('../pages/companyFlow/Index'))
const MailBox = lazy(() => import('../pages/mailBox/AllMails/AllMails'))
const CompnayMails = lazy(() =>
  import('../pages/mailBox/compnayMails/CompnayMails')
)
const AddNewMail = lazy(() => import('../pages/mailBox/addNewMail/AddNewMail'))
const EditMail = lazy(() => import('../pages/mailBox/editMail/EditMail'))
const ForwardMail = lazy(() =>
  import('../pages/mailBox/forwardMail/ForwardMail')
)
const BulkForward = lazy(() =>
  import('../pages/mailBox/bulkForward/BulkForward')
)
const Invoices = lazy(() => import('../pages/invoices/Invoices'))
const SignUpPassword = lazy(() =>
  import('../pages/signUp/signUpPassword/SignUpPassword')
)
const SignUpUploadPicture = lazy(() =>
  import('../pages/signUp/uploadPicture/SignUpUploadPicture')
)
const InoviceListing = lazy(() =>
  import('../pages/invoiceListing/InoviceListing')
)
const LandingPage = lazy(() => import('../pages/landingPage/LandingPage'))
const CompnayMailBoxScreen = lazy(() =>
  import('../pages/companyFlow/compnayMailBox/CompnayMailBoxScreen')
)

const MailsGroupForward = lazy(() =>
  import('../pages/mailsGroupForward/MailsGroupForward/MailsGroupForward')
)
const UnprocessedMailsForGroupForward = lazy(() =>
  import(
    '../pages/mailsGroupForward/unprocessedMailsForGroupForward/UnprocessedMailsForGroupForward'
  )
)
const EditForwardGroup = lazy(() =>
  import('../pages/mailsGroupForward/editForwardGroup/EditForwardGroup')
)
const EditGroupSelectedMails = lazy(() =>
  import(
    '../pages/mailsGroupForward/editGroupSelectedMails/EditGroupSelectedMails'
  )
)
const ForwardCheckDeposit = lazy(() =>
  import('../pages/mailBox/checkDepositForward/CheckDepositForward')
)
const MeetingBookingCalendar = lazy(() =>
  import('../pages/meetingBookings/MeetingBookingCalendar')
)
const MailOperatorProfile = lazy(() =>
  import('../pages/mailOperatorProfile/MailOperatorProfile')
)
const AppRoutes = [
  {
    id: 1,
    pathName: '/',
    component: LandingPage,
    isProtected: true,
  },
  {
    id: 2,
    pathName: '/login',
    component: Login,
    isProtected: false,
  },
  {
    id: 3,
    pathName: '/email-sent',
    component: PasswordReset,
    isProtected: false,
  },
  {
    id: 4,
    pathName: '/forget-password',
    component: ForgetPassword,
    isProtected: false,
  },
  {
    id: 5,
    pathName: '/success-screen',
    component: SuccessScreen,
    isProtected: false,
  },
  {
    id: 6,
    pathName: '/reset-password/:passwordLink',
    component: CreatePassword,
    isProtected: false,
  },
  {
    id: 7,
    pathName: '/employees/all-employees',
    component: AllEmployees,
    isProtected: true,
  },
  {
    id: 8,
    pathName: '/employees/add-employees',
    component: AddEmployees,
    isProtected: true,
  },
  {
    id: 9,
    pathName: '/employees/edit-employees/:userId',
    component: EditEmployees,
    isProtected: true,
  },
  {
    id: 10,
    pathName: '/all-locations',
    component: AllLocation,
    isProtected: true,
  },
  {
    id: 11,
    pathName: '/all-locations/create-locations',
    component: CreateLocations,
    isProtected: true,
  },
  {
    id: 12,
    pathName: '/all-employees',
    component: AllEmployees,
    isProtected: true,
  },
  {
    id: 13,
    pathName: '/employees',
    component: AllEmployees,
    isProtected: true,
  },
  {
    id: 14,
    pathName: '/all-locations/edit-locations/:locationId',
    component: EditLocation,
    isProtected: true,
  },
  {
    id: 15,
    pathName: '/all-users',
    component: UserList,
    isProtected: true,
  },
  {
    id: 16,
    pathName: '/all-employees/list/:roleId',
    component: AllEmployeesList,
    isProtected: true,
  },
  {
    id: 17,
    pathName: '/user/user-overview/:userID',
    component: UserOverview,
    isProtected: true,
  },
  {
    id: 18,
    pathName: '/user/create-account/:userID',
    component: CreateAccount,
    isProtected: true,
  },
  {
    id: 19,
    pathName: '/user/companies/:userID',
    component: AddCompanies,
    isProtected: true,
  },
  {
    id: 20,
    pathName: '/user/add-mails/:userID',
    component: AddMails,
    isProtected: true,
  },
  {
    id: 21,
    pathName: '/user/user-payments/:userID',
    component: UserPayment,
    isProtected: true,
  },
  {
    id: 22,
    pathName: '/user/all-invoices/:userID',
    component: AllInvoices,
    isProtected: true,
  },
  {
    id: 23,
    pathName: '/user/user-tickets/:userID',
    component: Tickets,
    isProtected: true,
  },
  {
    id: 24,
    pathName: '/user/user-activity/:userID',
    component: UserActivity,
    isProtected: true,
  },
  {
    id: 25,
    pathName: '/company/about/:companyID',
    component: AboutCompany,
    isProtected: true,
  },
  {
    id: 26,
    pathName: '/company/overview/:companyID',
    component: CompanyOverview,
    isProtected: true,
  },
  {
    id: 27,
    pathName: '/company/plans-products/:companyID',
    component: PlansAndProducts,
    isProtected: true,
  },
  {
    id: 28,
    pathName: '/company/mail-box/:companyID',
    component: CompnayMailBox,
    isProtected: true,
  },
  {
    id: 29,
    pathName: '/company/live-answering/:companyID',
    component: LiveAnswering,
    isProtected: true,
  },
  {
    id: 30,
    pathName: '/company/phone-number/:companyID',
    component: PhoneNumber,
    isProtected: true,
  },
  {
    id: 31,
    pathName: '/company/company-number/:companyID',
    component: PhoneNumber,
    isProtected: true,
  },
  {
    id: 32,
    pathName: '/company/company-payments/:companyID',
    component: CompnayPayments,
    isProtected: true,
  },
  {
    id: 33,
    pathName: '/company/company-invoices/:companyID',
    component: CompnayInvoices,
    isProtected: true,
  },
  {
    id: 35,
    pathName: '/company/company-activity/:companyID',
    component: CompnayActivity,
    isProtected: true,
  },
  {
    id: 36,
    pathName: '/company/company-support/:companyID',
    component: CompnaySupport,
    isProtected: true,
  },
  {
    id: 34,
    pathName: '/companies',
    component: AllCompanies,
    isProtected: true,
  },
  {
    id: 35,
    pathName: '/users/:userID',
    component: UserFlow,
    isProtected: true,
  },
  {
    id: 36,
    pathName: '/companies/:companyID',
    component: CompanieFlow,
    isProtected: true,
  },
  {
    id: 37,
    pathName: '/mailbox',
    component: MailBox,
    isProtected: true,
  },
  {
    id: 38,
    pathName: '/mailbox/addmail',
    component: AddNewMail,
    isProtected: true,
  },
  {
    id: 39,
    pathName: '/mailbox/forward-mail/:mailId',
    component: ForwardMail,
    isProtected: true,
  },
  {
    id: 40,
    pathName: '/mailbox/edit/:mailBoxId',
    component: EditMail,
    isProtected: true,
  },
  {
    id: 41,
    pathName: '/plan-and-products',
    component: PlanAndProducts,
    isProtected: true,
  },
  {
    id: 42,
    pathName: '/plan-and-products/edit-plan/:planId',
    component: EditPlan,
    isProtected: true,
  },
  {
    id: 43,
    pathName: '/plan-and-products/create-plan',
    component: CreatePlan,
    isProtected: true,
  },
  {
    id: 44,
    pathName: '/add-ons',
    component: AddOns,
    cou: 'idsd',
    isProtected: true,
  },
  {
    id: 45,
    pathName: '/add-ons/create-addon',
    component: CreateAddOns,
    isProtected: true,
  },
  {
    id: 46,
    pathName: '/add-ons/edit-addon/:addonId',
    component: EditAddOns,
    isProtected: true,
  },
  {
    id: 47,
    pathName: '/live-answering',
    component: LiveAnsweringPage,
    isProtected: true,
  },
  {
    id: 48,
    pathName: '/live-answering/add-new',
    component: AddNewCall,
    isProtected: true,
  },
  {
    id: 49,
    pathName: '/live-answering/add-new',
    component: AddNewCall,
    isProtected: true,
  },
  {
    id: 50,
    pathName: '/mailbox/:mailId',
    component: CompnayMails,
    isProtected: true,
  },
  {
    id: 51,
    pathName: '/mailbox/tab/:activeTabId',
    component: MailBox,
    isProtected: true,
  },
  {
    id: 52,
    pathName: [
      '/invoices/:id',
      '/companie/create-invoice/:companyId/:invoiceId',
    ],
    component: Invoices,
    isProtected: true,
  },
  {
    id: 53,
    pathName: '/setuppassword/:passwordLink',
    component: SignUpPassword,
    isProtected: false,
  },
  {
    id: 55,
    pathName: '/uploadpicture',
    component: SignUpUploadPicture,
    isProtected: false,
  },
  {
    id: 56,
    pathName: '/all-invoices',
    component: InoviceListing,
    isProtected: true,
  },
  {
    id: 57,
    pathName: [
      '/support',
      `/support/:mailID`,
      `/support/:folderId/:mailId/:messageId`,
    ],
    component: Support,
    isProtected: true,
  },
  {
    id: 58,
    pathName: '/settings/product-settings',
    component: ProductSettings,
    isProtected: true,
  },
  {
    id: 59,
    pathName: '/settings/general-settings',
    component: GeneralSettings,
    isProtected: true,
  },
  {
    id: 60,
    pathName: '/tickets/:ticketID',
    component: Ticket,
    isProtected: true,
  },
  {
    id: 61,
    pathName: '/ticketcenter',
    component: TicketCenter,
    isProtected: true,
  },
  {
    id: 62,
    pathName: '/chat-with-manager',
    component: ChatManager,
    isProtected: true,
  },
  {
    id: 63,
    pathName: '/landing-page',
    component: LandingPage,
    isProtected: true,
  },
  {
    id: 64,
    pathName: '/phone-number-details',
    component: PhoneNumberDetails,
    isProtected: true,
  },
  {
    id: 65,
    pathName: '/:contactId/:companyId/add-new-number',
    component: TemporaryPhoneNumberDetails,
    isProtected: true,
  },
  {
    id: 66,
    pathName: '/:contactId/:companyId/:numberId/edit-number',
    component: TemporaryPhoneNumberDetails,
    isProtected: true,
  },
  {
    id: 67,
    pathName: '/:contactId/:companyId/live-answering/:profileId/:profileNumber',
    component: TemporaryLiveAnsweringDetails,
    isProtected: true,
  },
  {
    id: 68,
    pathName: '/mailbox/:companyId/:mailBoxId/:mailBoxProfileNumber',
    component: CompnayMailBoxScreen,
    isProtected: true,
  },
  {
    id: 69,
    pathName: '/mailbox/group-forward',
    component: MailsGroupForward,
    isProtected: true,
  },
  {
    id: 70,
    pathName: '/mailbox/unprocessedmails',
    component: UnprocessedMailsForGroupForward,
    isProtected: true,
  },
  {
    id: 71,
    pathName: '/mailbox/edit-forward-group/:groupID',
    component: EditForwardGroup,
    isProtected: true,
  },
  {
    id: 72,
    pathName: '/mailbox/bulk-forward/:groupId',
    component: BulkForward,
    isProtected: true,
  },
  {
    id: 73,
    pathName: '/mailbox/edit-mail-group/:mailProfileId/:groupId',
    component: EditGroupSelectedMails,
    isProtected: true,
  },
  {
    id: 74,
    pathName: '/mailbox/forward-check-deposit/:mailId',
    component: ForwardCheckDeposit,
    isProtected: true,
  },
  {
    id: 75,
    pathName: '/meeting-calendar',
    component: MeetingBookingCalendar,
    isProtected: true,
  },
  {
    id: 76,
    pathName: '/:contactId/:companyId/live-answering',
    component: TemporaryLiveAnsweringDetailsEdit,
    isProtected: true,
  },
  {
    id: 77,
    pathName: '/admin-settings',
    component: AdminSettings,
    isProtected: true,
  },
  {
    id: 78,
    pathName: '/mailoperator',
    component: MailOperatorProfile,
    isProtected: true,
  },
]
export default AppRoutes
