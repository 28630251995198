import React, { Fragment, useState, useEffect, useRef } from "react";
import CommonHaeder from "../../components/header/commonHeader/CommonHaeder";
import TicketCenterSubHeader from "../../components/ticketCenter/ticketCenterSubHeader/TicketCenterSubHeader";
import TicketCenterFilters from "../../components/ticketCenter/ticketCenterFilters/TicketCenterFilters";
import { MainConatiner, BoardContainer } from "./styles";
import Board from "react-trello";
import { Avatar, Drawer, Popconfirm, Skeleton } from "antd";
import TicketBoardCards from "../../components/ticketCenter/ticketCards/TicketBoardCards";
import TicketCenterSearchBar from "../../components/ticketCenter/ticketCenterSearchBar/TicketCenterSearchBar";
import useGetData from "../../hooks/useGetData";
import { useNavigate } from "react-router-dom";
import { InstanceWithAuth } from "../../App";
import { useSelector } from "react-redux";
import { CloseOutlined } from "@ant-design/icons";
import FirstColumn from "../../components/ticket/FirstColumn/FirstColumn";
import UserDetailSidebar from "../../components/userList/userDetailSidebar/UserDetailSidebar";
import CompanyDetailSidebar from "../../components/componyFlowComponents/companyDetailSidebar/CompanyDetailSidebar";
import CustomTooltip from "../../components/allEmpolyeesComponents/AllEmpolyeesCard/customTooltip/CustomTooltip";
import NameAvatar from "../../common/NameAvatar";
import DataNotFound from "../../common/DataNotFound";
import { useGlobalAlertMessage } from "../../hooks/useAlertMessage";
import { RiUserAddLine } from "react-icons/ri";

function TicketCenter() {
  const { auth } = useSelector((state) => state);
  const { app } = useSelector((state) => state);
  const [selectedPipeline, setSelectedPipeline] = useState("");
  const [type, setType] = useState("");
  const [selectedOwner, setSelectedOwner] = useState("");
  const [selectedPriority, setSelectedPriority] = useState("");
  const [selectedCreatedDate, setSelectedCreatedDate] = useState("");
  const [selectedLastActivityDate, setSelectedLastActivityDate] = useState("");
  const [queryParam, setQueryParam] = useState("");
  const [selectedDate, setSelectTime] = useState("");
  const [startDate, setStartDate] = useState("");
  const [selectedLocation, setSelectedLocation] = useState();
  const [endDate, setEndDate] = useState("");
  const [filterDateType, setFilterDateType] = useState("");
  const [advancedFilters, setAdvancedFilters] = useState(null);
  const [loadingFilteredTicktes, setLoadingFilteredTicktes] = useState(false);
  const [openTicketDrawer, setOpenTicketDrawer] = useState(false);
  const [ticketData, setTicketData] = useState(null);
  const [allCardsClosed, setAllCardsClosed] = useState(false);
  const { showMessage } = useGlobalAlertMessage();
  const [openContactDrawer, setOpenContactDrawer] = useState(false);
  const [openedCardId, setOpenedCardId] = useState(null);
  const [isShowCompnayDetails, setIsShowCompnayDetails] = useState(false);
  const [isShowContactDetails, setIsShowContactDetails] = useState(false);
  const [slectedCompnayID, setSelectedCompanyId] = useState("");

  useEffect(() => {
    const saveFilters = setTimeout(() => {
      if (
        !selectedPipeline &&
        !selectedOwner &&
        !selectedPriority &&
        !selectedCreatedDate &&
        !selectedLastActivityDate &&
        !startDate &&
        !endDate &&
        !selectedLocation &&
        !queryParam &&
        !type
      ) {
        return;
      }
      const filters = {
        selectedPipeline: selectedPipeline || "",
        selectedOwner: selectedOwner || "",
        selectedPriority: selectedPriority || "",
        selectedCreatedDate: selectedCreatedDate || "",
        selectedLastActivityDate: selectedLastActivityDate || "",
        startDate: startDate || "",
        endDate: endDate || "",
        selectedLocation: selectedLocation || "",
        queryParam: queryParam || "",
        type: type || "",
      };

      localStorage.setItem("ticketsTasksFilters", JSON.stringify(filters));
    }, 300);
    return () => clearTimeout(saveFilters);
  }, [
    selectedPipeline,
    selectedOwner,
    selectedPriority,
    selectedCreatedDate,
    selectedLastActivityDate,
    startDate,
    endDate,
    selectedLocation,
    queryParam,
    type,
  ]);

  useEffect(() => {
    const savedFilters = JSON.parse(
      localStorage.getItem("ticketsTasksFilters")
    );
    if (savedFilters) {
      setSelectedPipeline(savedFilters.selectedPipeline || "");
      setSelectedOwner(savedFilters.selectedOwner || "");
      setSelectedPriority(savedFilters.selectedPriority || "");
      setSelectedCreatedDate(savedFilters.selectedCreatedDate || "");
      setSelectedLastActivityDate(savedFilters.selectedLastActivityDate || "");
      setStartDate(savedFilters.startDate || "");
      setEndDate(savedFilters.endDate || "");
      setSelectedLocation(savedFilters.selectedLocation || "");
      setQueryParam(savedFilters.queryParam || "");
      setType(savedFilters.type || "");
    }
  }, []);

  const {
    data: ticketBoardData,
    refetchData: refetchTikcedBoardData,
    loading: ticketBoardDataLoading,
  } = useGetData(
    `support/ticket-board?support_pipeline_id=${
      app?.userSelcetedPipeLine ? app?.userSelcetedPipeLine?.id : ""
    }&owner=${
      selectedOwner && selectedOwner?.name != "all" ? selectedOwner?.id : ""
    }&priority=${
      selectedPriority?.name && selectedPriority?.name != "all"
        ? selectedPriority?.name
        : ""
    }&location_id=${
      selectedLocation && selectedLocation?.name != "all"
        ? selectedLocation?.key
        : ""
    }&query=${queryParam ? queryParam : ""}&from_date=${
      startDate && startDate != "-undefined-undefined" ? startDate : ""
    }&to_date=${endDate && endDate != "-undefined-undefined" ? endDate : ""}`
  );
  const navigate = useNavigate();
  useEffect(() => {
    refetchTikcedBoardData();
  }, [
    selectedPipeline,
    selectedOwner,
    selectedPriority,
    queryParam,
    selectedLocation,
  ]);

  useEffect(() => {
    if (selectedCreatedDate?.name == "all") {
      setStartDate("");
      setEndDate("");
      refetchTikcedBoardData();
    }
  }, [selectedCreatedDate]);

  const showDrawerContactDrawer = () => {
    setOpenTicketDrawer(true);
  };
  const onCloseTicketDrawer = () => {
    setOpenTicketDrawer(false);
    setIsShowCompnayDetails(false);
    setSelectedCompanyId("");
  };

  const isFirstLoad = useRef(true);
  let showLoading = false;
  const now = new Date();
  const cardStyle = {
    border: "1px solid #4cbbcb",
    borderRadius: "8px",
    padding: "10px",
    margin: "5px 0px ",
    boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.1)",
    fontSize: "14px",
    color: "#2b3746",
    fontFamily: "Outfit",
    fontStyle: "normal",
    fontWeight: "500 !important",
    lineHeight: "normal",
    letterSpacing: "-0.49px",
    width: "250px",
    fontWeight: "400",
  };
  const laneStyle = {
    backgroundColor: "rgba(76, 187, 203, 0.2)",
    fontSize: "20px",
    fontWeight: "600",
  };
  function timeAgo(dateString, status) {
    const date = new Date(dateString);

    if (isNaN(date.getTime())) {
      return "Invalid date";
    }

    const now = new Date();
    const seconds = Math.floor((now - date) / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(seconds / 3600);
    const days = Math.floor(seconds / 86400);
    //
    let timeString;

    if (days >= 1) {
      timeString = days === 1 ? "1 day" : `${days} days`;
    } else if (hours >= 1) {
      timeString = hours === 1 ? "1 hour" : `${hours} hours`;
    } else if (minutes >= 1) {
      timeString = minutes === 1 ? "1 minute" : `${minutes} minutes`;
    } else {
      timeString = "Just now";
    }

    if (status === "Open") {
      return timeString === "Just now"
        ? "Opened just now"
      : `Open for ${timeString}`;
    } else {
      // return timeString === "Just now" ? "Just now" : `${timeString} ago`;
      return "Closed";
    }

    // if (status === "Open") {
    //   return timeString === "Just now"
    //     ? "Opened just now"
    //     : `Open for ${timeString}`;
    // } else {
    //   return timeString === "Just now" ? "Just now" : `${timeString} ago`;
    // }
  }

  const [count, setCount] = useState(0); // Global count for tracking movements
  const [mappedData, setMappedData] = useState({ lanes: [] });

  // Initialize lanes and cards
  const truncateText = (text, maxLength = 100) => {
    if (!text) return "";
    return text.length > maxLength
      ? `${text.substring(0, maxLength)}...`
      : text;
  };
  const handleShowUserDetails = (e, compnayId, type) => {
    console.log(e, "checking console");
    // e.stoppropagation()

    if (type === "company") {
      setOpenTicketDrawer(false);
      setIsShowContactDetails(false);
      setIsShowCompnayDetails(true);
      setSelectedCompanyId(compnayId);
    } else if (type === "conatct") {
      setOpenTicketDrawer(false);

      setIsShowCompnayDetails(false);
      setIsShowContactDetails(true);
      setSelectedCompanyId(compnayId);
    }
  };

  const CustomCard = ({
    title,
    description,
    owner,
    avatar,
    label,
    id,
    companyName,
    companyAvatar,
    status,
    companyId,
    contactName,
    contactAvatar,
    contactId,
  }) => (
    <div
      style={{
        background: "rgb(248 252 252)",
        borderRadius: "8px",
        padding: "10px",
        marginBottom: "8px",
        border: "1px solid #b2ebf2",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        minHeight: "80px",
        cursor: "pointer",
      }}
      onClick={() => handleCardClick(id)}
    >
      {/* {console.log(companyName, 'companyName')}
      {console.log(companyAvatar, 'companyAvatar')} */}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "start",
        }}
      >
        <div
          style={{
            fontSize: "14px",
            fontWeight: "500",
            // color: '#1397b2',
            fontFamily: "Outfit",
            width: "100%",
          }}
        >
          {title}
        </div>
      </div>

      <div
        style={{
          fontSize: "12px",
          fontWeight: "400",
          // color: '#00796B',
          fontFamily: "Outfit",
        }}
      >
        {timeAgo(label, status)}
      </div>
      <div
        style={{
          fontSize: "12px",
          color: "#333",
          marginTop: "4px",
          display: "flex",
        }}
      >
        <div style={{ fontWeight: "500" }}>Ticket Owner </div> :{" "}
        {owner ? (
          owner
        ) : (
          <Popconfirm
            title="Are you sure to own?"
            description="Are you sure to own ticket?"
            onConfirm={(e) => {
              e.stopPropagation();
              handleOwnTicket(id, owner);
            }}
            onCancel={(e) => e.stopPropagation()}
            okText="Yes"
            cancelText="No"
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "4px",
                cursor: "pointer",
                paddingLeft: "3px",
              }}
              onClick={(e) => e.stopPropagation()}
            >
              Unassigned{" "}
              <RiUserAddLine
                color="#4cbbcb"
                style={{
                  transition: "transform 0.2s ease, filter 0.2s ease",
                }}
                onMouseEnter={(e) => {
                  e.target.style.transform = "scale(1.3)";
                  e.target.style.filter = "brightness(1.2)";
                }}
                onMouseLeave={(e) => {
                  e.target.style.transform = "scale(1)";
                  e.target.style.filter = "brightness(1)";
                }}
              />
            </div>
          </Popconfirm>
        )}
      </div>
      <div style={{ fontSize: "12px", color: "#333", marginTop: "4px" }}>
        {description}
      </div>

      {/* Horizontal Line */}
      <div style={{ marginTop: "10px", borderTop: "1px solid #ccc" }}></div>
      <div style={{ display: "flex", alignItems: "center", gap: "2px" }}>
        {contactAvatar != null ? (
          <CustomTooltip text={contactName} marginleft="3rem" marginTop="-30px">
            <img
              onClick={(e) => handleShowUserDetails(e, contactId, "conatct")}
              src={contactAvatar}
              alt="avatar"
              style={{
                width: "24px",
                height: "24px",
                borderRadius: "50%",
                // marginRight: '8px',
                marginTop: "12px",
                cursor: "pointer",
              }}
            />
          </CustomTooltip>
        ) : contactName != null && contactAvatar == null ? (
          <CustomTooltip text={contactName} marginleft="3rem" marginTop="-10px">
            <div
              style={{
                // marginRight: '8px',
                marginTop: "10px",
                cursor: "pointer",
              }}
              onClick={(e) => handleShowUserDetails(e, contactId, "conatct")}
            >
              <NameAvatar
                val={20}
                userName={contactName}
                fontSize={12}
              ></NameAvatar>
            </div>
          </CustomTooltip>
        ) : (
          <CustomTooltip text={"Unassigned"}>
            <div
              style={{
                // marginRight: '8px',
                marginTop: "10px",
                cursor: "pointer",
              }}
            >
              <span
                style={{
                  width: "20px",
                  height: "20px",
                  borderRadius: "50%",
                  backgroundColor: "#ccc",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  // marginRight: '8px',
                  cursor: "pointer",
                }}
              ></span>
            </div>
          </CustomTooltip>
        )}

        {companyAvatar != null ? (
          <CustomTooltip text={companyName} marginleft="3rem" marginTop="-30px">
            <img
              onClick={(e) => handleShowUserDetails(e, companyId, "company")}
              src={companyAvatar}
              alt="avatar"
              style={{
                width: "24px",
                height: "24px",
                borderRadius: "50%",
                // marginRight: '8px',
                marginTop: "12px",
                cursor: "pointer",
              }}
            />
          </CustomTooltip>
        ) : companyName != null && companyAvatar == null ? (
          <CustomTooltip text={companyName} marginleft="3rem" marginTop="-10px">
            <div
              style={{
                // marginRight: '8px',
                marginTop: "10px",
                cursor: "pointer",
              }}
              onClick={(e) => handleShowUserDetails(e, companyId, "company")}
            >
              <NameAvatar
                val={20}
                userName={companyName}
                fontSize={12}
              ></NameAvatar>
            </div>
          </CustomTooltip>
        ) : (
          <CustomTooltip text={"Unassigned"}>
            <div
              style={{
                // marginRight: '8px',
                marginTop: "10px",
                cursor: "pointer",
              }}
            >
              <span
                style={{
                  width: "20px",
                  height: "20px",
                  borderRadius: "50%",
                  backgroundColor: "#ccc",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  // marginRight: '8px',
                  cursor: "pointer",
                }}
              ></span>
            </div>
          </CustomTooltip>
        )}

        {/* {avatar != null ? (
          <CustomTooltip text={owner} marginleft="3rem" marginTop="-30px">
            <img
              onClick={(e) => {
                e.stopPropagation();
              }}
              src={avatar}
              alt="avatar"
              style={{
                width: "24px",
                height: "24px",
                borderRadius: "50%",
                // marginRight: '8px',
                marginTop: "12px",
                cursor: "pointer",
                boxShadow: "10px -2px 17px -5px rgba(191,195,219,1)",
              }}
            />
          </CustomTooltip>
        ) : owner != null && avatar == null ? (
          <CustomTooltip text={owner} marginleft="3rem" marginTop="-10px">
            <div
              onClick={(e) => {
                e.stopPropagation();
              }}
              style={{
                // marginRight: '8px',
                marginTop: "10px",
                cursor: "pointer",
                boxShadow: "10px -2px 17px -5px rgba(191,195,219,1)",
              }}
            >
              <NameAvatar val={20} userName={owner} fontSize={12}></NameAvatar>
            </div>
          </CustomTooltip>
        ) : (
          <CustomTooltip
            text={"Unassigned"}
            marginleft="3rem"
            marginTop="-10px"
          >
            <div
              style={{
                // marginRight: '8px',
                marginTop: "10px",
                cursor: "pointer",
              }}
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <Popconfirm
                title="Are you sure to own?"
                description="Are you sure to own ticket?"
                onConfirm={(e) => {
                  e.stopPropagation();
                  handleOwnTicket();
                }}
                // onCancel={c}
                okText="Yes"
                cancelText="No"
              >
                <span
                  style={{
                    width: "20px",
                    height: "20px",
                    borderRadius: "50%",
                    backgroundColor: "#ccc",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    // marginRight: '8px',
                    cursor: "pointer",
                    boxShadow: "10px -2px 17px -5px rgba(191,195,219,1)",
                  }}
                >
                  <RiUserAddLine color="#4cbbcb" />
                </span>
              </Popconfirm>
            </div>
          </CustomTooltip>
        )} */}
      </div>
    </div>
  );

  const handleOwnTicket = async (id) => {
    const data = {
      user_id: auth?.userObj?.id || "",
    };

    const encodedData = new URLSearchParams(data).toString();

    try {
      const response = await InstanceWithAuth.put(
        `support/tickets/${id}`,
        encodedData,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: `Bearer ${auth?.userObj?.api_token}`,
          },
        }
      );

      if (response.status === 201 || response.status === 200) {
        refetchTikcedBoardData();
        showMessage({
          type: "success",
          content: response?.data?.message,
        });
      }
    } catch (error) {
      showMessage({
        type: "error",
        content: error?.response?.data?.message,
      });
    }
  };

  useEffect(() => {
    const initializeLanes = () => {
      const dataSource =
        advancedFilters?.status === "success"
          ? advancedFilters.data
          : Array.isArray(ticketBoardData?.data)
          ? ticketBoardData.data
          : [];

      if (Array.isArray(dataSource) && dataSource.length > 0) {
        let allClosed = true; // Assume all cards are closed initially

        const lanes = dataSource.map((stage) => {
          const cards = Array.isArray(stage?.cards)
            ? stage.cards.map((card) => {
                // Check if any card is not closed
                if (card.status !== "Closed") {
                  allClosed = false;
                }
                return {
                  id: card?.id?.toString(),
                  title: card?.title,
                  label: card?.label,
                  description: truncateText(card?.description, 80),
                  owner: card?.owner || null,
                  metadata: { sha: "be312a1" },
                  avatar: card?.avatar || null,
                  companyName: card?.company_name || null,
                  companyAvatar: card?.company_avatar || null,
                  status: card?.status || null,
                  companyId: card?.company_id,
                  contactName: card?.contact_name || null,
                  contactAvatar: card?.contact_avatar || null,
                  contactId: card?.contact_id,
                };
              })
            : [];

          return {
            id: stage?.id?.toString(),
            title: stage.title,
            label: `${stage?.cards?.length || 0}`,
            style: {
              background: "white",
              fontSize: "14px",
              color: "#2b3746",
              fontFamily: "Outfit",
              fontStyle: "normal",
              fontWeight: "400",
              lineHeight: "normal",
              letterSpacing: "-0.49px",
              height: "410px",
              width: "280px",
              margin: "5px 5px 0px 0px",
              header: {
                height: "30px",
              },
            },
            cards,
          };
        });

        setMappedData({ lanes });
        setAllCardsClosed(allClosed);
      } else {
        setMappedData({ lanes: [] });
        setAllCardsClosed(false);
      }
    };

    initializeLanes();
  }, [ticketBoardData?.data, advancedFilters?.data]);

  const openFiltersDrawer = localStorage.getItem("openFiltersDrawer");
  useEffect(() => {
    if (openFiltersDrawer) {
      setOpenContactDrawer(true);
      setOpenContactDrawer(true);
    }
  }, [openFiltersDrawer]);

  const handleDragEnd = (
    cardId,
    sourceLaneId,
    targetLaneId,
    position,
    cardDetails
  ) => {
    setMappedData((prevData) => {
      const updatedLanes = prevData.lanes.map((lane) => {
        // Case: Same lane
        if (lane.id === sourceLaneId && sourceLaneId === targetLaneId) {
          // Reorder cards within the same lane
          const cardIndex = lane.cards.findIndex((card) => card.id === cardId);
          const updatedCards = [...lane.cards];
          const [movedCard] = updatedCards.splice(cardIndex, 1); // Remove the card
          updatedCards.splice(position, 0, movedCard); // Add it back at the new position

          return {
            ...lane,
            cards: updatedCards,
            label: `${updatedCards.length}`, // Update label
          };
        }

        // Case: Source lane
        if (lane.id === sourceLaneId) {
          return {
            ...lane,
            cards: lane.cards.filter((card) => card.id !== cardId), // Remove card
            label: `${lane.cards.length - 1}`, // Update label
          };
        }

        // Case: Target lane
        if (lane.id === targetLaneId) {
          return {
            ...lane,
            cards: [
              ...lane.cards.slice(0, position),
              cardDetails, // Add card at new position
              ...lane.cards.slice(position),
            ],
            label: `${lane.cards.length + 1}`, // Update label
          };
        }

        return lane;
      });

      setCount((prevCount) => prevCount + 1);

      return { lanes: updatedLanes };
    });

    handleSubmit(cardId, sourceLaneId, targetLaneId, position, cardDetails);
  };
  const handleCardClick = async (cardId) => {
    setOpenedCardId(cardId);
    setOpenTicketDrawer(true);
    fetchTicketData(cardId);
  };

  const fetchTicketData = async (cardId) => {
    setTicketData(null);
    try {
      const response = await InstanceWithAuth.get(`support/tickets/${cardId}`, {
        headers: {
          Authorization: `Bearer ${auth?.userObj?.api_token}`,
        },
      });

      setTicketData(response);
    } catch (error) {}
  };

  const handleSubmit = async (
    cardId,
    sourceLaneId,
    targetLaneId,
    position,
    cardDetails
  ) => {
    if (sourceLaneId == targetLaneId) return null;
    const data = {
      support_pipeline_id: app?.userSelcetedPipeLine?.id,
      support_pipeline_stage_id: targetLaneId,
    };
    const encodedData = new URLSearchParams(data).toString();
    try {
      const response = await InstanceWithAuth.put(
        `support/tickets/${cardId}`,
        encodedData,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: `Bearer ${auth?.userObj?.api_token}`,
          },
        }
      );

      if (response.status === 201 || response.status === 200) {
        showMessage({
          type: "success",
          content: response?.data?.message,
        });

        if (openTicketDrawer) {
          if (cardId === openedCardId) {
            fetchTicketData(cardId);
          }
        }
      }
    } catch (error) {
      showMessage({
        type: "error",
        content: error?.response?.data?.message,
      });
    }
  };
  const CustomLaneHeader = ({ title, label }) => (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "flex-start",
        height: "30px",
        padding: "10px",
        fontSize: "15px",
        fontWeight: "500",
        marginBottom: "0px",
        padding: "0px 5px",
      }}
    >
      <span>{title}</span>
      <span style={{ fontSize: "12px", color: "#6b6b6b" }}>{label}</span>
    </div>
  );

  return (
    <Fragment>
      <CommonHaeder
        firstBcItem={app?.userSelcetedPipeLine?.type}
        firstBcIcon="logTickets"
        secondBcItem={app?.userSelcetedPipeLine?.name}
        secondBcIcon="logTickets"
      />
      <TicketCenterSubHeader />
      <MainConatiner>
        <TicketCenterFilters
          selectedPipeline={selectedPipeline}
          setSelectedPipeline={setSelectedPipeline}
          type={type}
          setType={setType}
          selectedOwner={selectedOwner}
          setSelectedOwner={setSelectedOwner}
          selectedPriority={selectedPriority}
          setSelectedPriority={setSelectedPriority}
          selectedCreatedDate={selectedCreatedDate}
          setSelectedCreatedDate={setSelectedCreatedDate}
          selectedLastActivityDate={selectedLastActivityDate}
          setSelectedLastActivityDate={setSelectedLastActivityDate}
          app={app}
          selectedOption={selectedDate}
          setSelectedOption={setSelectTime}
          setEndDate={setEndDate}
          setStartDate={setStartDate}
          startDate={startDate}
          setFilterDateType={setFilterDateType}
          filterDateType={filterDateType}
          setSelectedLocation={setSelectedLocation}
          selectedLocation={selectedLocation}
          setAdvancedFilters={setAdvancedFilters}
          setLoadingFilteredTicktes={setLoadingFilteredTicktes}
          openContactDrawer={openContactDrawer}
          setOpenContactDrawer={setOpenContactDrawer}
          refetchTikcedBoardData={refetchTikcedBoardData}
        />
        <BoardContainer>
          <TicketCenterSearchBar
            setQueryParam={setQueryParam}
            allCardsClosed={allCardsClosed}
            ticketBoardDataLoading={ticketBoardDataLoading}
          />
          <div className="kanban-board">
            {!ticketBoardDataLoading &&
            !loadingFilteredTicktes &&
            mappedData.lanes.length == 0 ? (
              <div
                style={{
                  minHeight: "280px",
                  display: "flex",
                  alignItems: "flex-end",
                }}
              >
                <DataNotFound
                  label={"Good Job! You are up to date with this pipeline"}
                ></DataNotFound>
              </div>
            ) : ticketBoardDataLoading || loadingFilteredTicktes ? (
              <Skeleton active></Skeleton>
            ) : mappedData?.lanes && mappedData?.lanes?.length > 0 ? (
              <Board
                data={mappedData}
                draggable
                // collapsibleLanes

                components={{
                  Card: (props) => (
                    <>
                      <CustomCard
                        title={props.title}
                        description={props.description}
                        owner={props.owner}
                        avatar={props.avatar}
                        label={props.label}
                        id={props.id}
                        companyName={props.companyName}
                        companyAvatar={props.companyAvatar}
                        status={props.status}
                        companyId={props.companyId}
                        contactName={props.contactName}
                        contactAvatar={props.contactAvatar}
                        contactId={props.contactId}
                      />
                    </>
                  ),
                  LaneHeader: CustomLaneHeader,
                }}
                laneButtonStyle={{ display: "none" }}
                handleDragEnd={handleDragEnd}
                cardStyle={cardStyle}
                onCardClick={handleCardClick}
                hideCardDeleteIcon={true}
                laneStyle={laneStyle}
              />
            ) : !ticketBoardDataLoading &&
              !loadingFilteredTicktes &&
              mappedData.lanes.length == 0 ? (
              <div
                style={{
                  minHeight: "280px",
                  display: "flex",
                  alignItems: "flex-end",
                }}
              >
                <DataNotFound
                  label={"Good Job! You are up to date with this pipeline"}
                ></DataNotFound>
              </div>
            ) : null}
          </div>
        </BoardContainer>

        <Drawer
          mask={false}
          className="drawer"
          width={400}
          padding={0}
          paddingLG={0}
          paddingXS={0}
          onClose={onCloseTicketDrawer}
          open={openTicketDrawer}
          headerStyle={{
            display: "none",
          }}
          bodyStyle={{
            padding: isShowCompnayDetails ? "0px" : "",
          }}
          // styles={{
          //   padding: isShowCompnayDetails ? '0px' : '',
          // }}
          closeIcon={
            <CloseOutlined style={{ color: "white", fontSize: "bold" }} />
          }
          title="All Filters"
        >
          {isShowCompnayDetails ? (
            <CompanyDetailSidebar
              open={true}
              userID={slectedCompnayID}
              canHide={true}
              setSideBarOpen={onCloseTicketDrawer}
              setOpen={onCloseTicketDrawer}
              buttonSize={"-webkit-fill-available"}
            />
          ) : isShowContactDetails ? (
            <UserDetailSidebar
              open={true}
              userID={slectedCompnayID}
              canHide={true}
              setSideBarOpen={onCloseTicketDrawer}
              setOpen={onCloseTicketDrawer}
              buttonSize={"-webkit-fill-available"}
            />
          ) : (
            <>
              {!ticketData?.data ? (
                <>
                  <Skeleton />
                </>
              ) : (
                <FirstColumn
                  ticket={ticketData?.data?.data}
                  refetchData={fetchTicketData}
                  CallingFromTicketCenter={true}
                  setOpenTicketDrawer={setOpenTicketDrawer}
                  refetchTikcedBoardData={refetchTikcedBoardData}
                />
              )}
            </>
          )}
        </Drawer>
      </MainConatiner>
    </Fragment>
  );
}

export default TicketCenter;
